<template>
  <div
    class="app-container"
    :class="{
      'sticky-product-summary-is-visible': isShowStickyProductSummary && !productGalleryOpen,
      'sticky-apple-pay-is-visible': isShowStickyApplePaySummary,
      'gallery--open': productGalleryOpen,
    }"
  >
    <div v-if="loading.app" aria-label="Loading" class="loader-container">
      <transition name="fade" mode="out-in">
        <lottie-animation :animationData="loaderAnimation" :loop="true" />
      </transition>
    </div>

    <template v-if="loading.logo">
      <transition name="fade" mode="out-in">
        <PaymentPopupSplashScreen v-if="isPaymentPopup" />
        <SplashScreen v-else />
      </transition>
    </template>

    <template v-if="!loading.app">
      <div class="main-container" ref="mainCont" v-show="!loading.logo">
        <header class="header-container" :style="'background:' + primaryColor">
          <Logo />

          <button
            v-if="isShowHeaderCloseButton"
            type="button"
            class="button button--close"
            data-test="header-close-button"
            @click="closeCheckout()"
          >
            <IconClose />
            Close
          </button>

          <button
            v-if="isShowHeaderBackButton"
            type="button"
            class="button button--back colour-white"
            @click.prevent="doPreviousStepInCheckout()"
          >
            <IconBack />
            Back
          </button>
        </header>

        <main style="background: white">
          <router-view v-slot="{ Component, route }">
            <transition name="fade" mode="out-in">
              <div :key="route.name">
                <component :is="Component" />
              </div>
            </transition>
          </router-view>
        </main>

        <Footer v-if="isShowFooter" />

        <LaunchPaymentPopup
          v-if="paymentPopupState && transaction?.token && paymentPopupState !== 'IS_CLOSED'"
          data-test="launch-payment-poupup"
          :paymentPopupState="paymentPopupState"
          :transactionToken="transaction.token"
          :cartJwt="cartJwt"
          :paymentProvider="paymentProvider"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { mapState, mapWritableState, mapActions } from 'pinia';
import { useMainStore } from '@/stores/MainStore.ts';
import { useProductStore } from '@/stores/ProductStore.ts';
import { useCartStore } from '@/stores/CartStore.ts';
import { usePaymentStore } from '@/stores/PaymentStore.ts';

import SplashScreen from '@/components/SplashScreen/SplashScreen.vue';
import Logo from '@/components/Logo/index.vue';
import IconClose from '@/components/icons/IconClose.vue';
import IconBack from '@/components/icons/IconBack.vue';
import IconLogo from '@/components/icons/IconLogo.vue';
import PaymentPopupSplashScreen from './components/SplashScreen/PaymentPopupSplashScreen.vue';
import LaunchPaymentPopup from './components/Payment/LaunchPaymentPopup.vue';
import Footer from '@/components/Footer/index.vue';

import constants from '@/constants';
import loader from './assets/loader.json';
import { useAdvertiserStore } from '@/stores/AdvertiserStore';
import { getRouter } from '@/router';
import gtmTracker from '@/helpers/googleTagManager';
import { ShopthruError } from '@/types/errors.types';

export default {
  components: {
    Logo,
    IconLogo,
    IconClose,
    IconBack,
    SplashScreen,
    PaymentPopupSplashScreen,
    LaunchPaymentPopup,
    Footer,
  },
  computed: {
    ...mapState(useMainStore, ['loading', 'isPreviewMode', 'isPaymentPopup', 'paymentPopupState']),
    ...mapState(usePaymentStore, ['transaction']),
    ...mapWritableState(useMainStore, ['supports']),
    ...mapState(useAdvertiserStore, ['primaryColor', 'paymentProvider']),
    ...mapState(useProductStore, [
      'isShowStickyProductSummary',
      'isShowStickyApplePaySummary',
      'productGalleryOpen',
    ]),
    ...mapState(useCartStore, ['cartJwt']),

    // Get loader animation from assets
    loaderAnimation() {
      return loader;
    },
    isShowHeaderCloseButton() {
      return (
        ['Product', 'Thankyou', 'Error', 'ProductOutOfStock', 'ProductNotFound'].includes(
          this.$route.name,
        ) &&
        !this.isPreviewMode &&
        !this.isPaymentPopup
      );
    },
    isShowHeaderBackButton() {
      return (
        !['Product', 'Thankyou', 'Error', 'ProductOutOfStock', 'ProductNotFound'].includes(
          this.$route.name,
        ) && !this.isPaymentPopup
      );
    },
    async isShowFooter() {
      const router = await getRouter();

      // TODO add Payment Popup when merged
      return ['Product'].some((pageName) => pageName === router.currentRoute.value.name);
    },
  },
  setup() {
    const mainStore = useMainStore();
    const searchParams = new URLSearchParams(window.location.search);
    const code = location.pathname.split('/').pop();
    const parentDomain = searchParams.get('parentdomain');
    const isPreviewMode = searchParams.get('isPreviewMode') === 'true';
    const isPaymentPopup =
      searchParams.get(constants.PAYMENT_POPUP_WINDOW.isPaymentPopupLaunchIdentifier) === 'true'; // i.e. is dedicated popup window for payment workarounds
    const mainStoreData = {
      code,
      parentDomain,
      isPreviewMode,
      isPaymentPopup,
    };

    if (!parentDomain) {
      // TODO - DS work out best way to handle this error
      // throw new Error("Missing required parent domain!");
    }

    mainStore.loadJsAssets();
    mainStore.setData(mainStoreData);

    if (isPaymentPopup) {
      getRouter().then((router) => {
        router.replace('/payment-popup');
      });
    }
  },
  created() {
    const component = this;

    // Listen for unhandled promise rejections
    window.addEventListener('unhandledrejection', (e) => {
      const reasonObj = e.reason;

      if (['local', 'dev', 'staging'].includes(import.meta.env.VITE_APP_ENV)) {
        console.info('Dev note - global unhandledrejection handler called', reasonObj);
      }

      gtmTracker.trackJourneyEvent({
        event: 'unhandledrejection_error_thrown',
      });

      let isGoToErrorPage = false;

      // Whitelist the error types so we don't go to error page on just any unhandled error event
      if (Object.getPrototypeOf(reasonObj) instanceof ShopthruError) {
        // Handle all appropriate payment errors by going to the error page, this will be more finely tuned moving forwards.
        isGoToErrorPage = true;

        // Ignore the error thrown when user closes the Google Pay dialogue
        if (reasonObj.sourceError?.statusCode === 'CANCELED') {
          isGoToErrorPage = false;
        }
      }

      if (isGoToErrorPage) {
        e.preventDefault();

        component.goToErrorPage({
          error: reasonObj,
        });
      }
    });
  },
  mounted() {
    // Make this Vue instance accessible from the payment popup
    window.ParentVueInstance = this;

    gtmTracker.trackJourneyEvent({
      event: 'app_loaded',
    });
  },
  methods: {
    ...mapActions(useMainStore, [
      'closeCheckout',
      'previousStepInCheckout',
      'goToErrorPage',
      'onLaunchPaymentPopup',
    ]),

    async doPreviousStepInCheckout() {
      const router = await getRouter();
      this.previousStepInCheckout(router);
    },
  },
};
</script>

<style lang="scss">
@import './styles/_app.scss';
</style>
