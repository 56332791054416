import { defineStore } from 'pinia';

// Stores
import { useMainStore } from '@/stores/MainStore.ts';

export const usePublisherStore = defineStore('publisherStore', {
  state: () => {
    return {
      publisherId: null,
      publisherEmail: '',
      publisherName: '',
      publisherUrl: '',
      publisherLogo: null,
      showPublisherLogoOnLoad: false,
    };
  },
  actions: {
    setData(publisher) {
      // Set Is dev
      const mainStore = useMainStore();

      this.publisherId = publisher.id;
      this.publisherEmail = publisher.email;
      this.publisherName = publisher.name;
      this.publisherLogo = publisher.logo_url;
      this.showPublisherLogoOnLoad = publisher.show_logo_on_load;
      this.publisherUrl = mainStore.parentDomain;
    },
  },
});
