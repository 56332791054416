<template>
  <div class="form-container">
    <!-- TODO implement proper spinner -->
    <div v-if="!isAllElementsAreReady()" data-test="forms-loading-indication">Loading...</div>

    <div v-show="isAllElementsAreReady()" class="mt-20" data-test="main-forms-content">
      <!-- Stripe Payment Element -->
      <h4>Card Details</h4>
      <StripeElement
        ref="paymentElement"
        type="payment"
        :elements="elementsInstance"
        :options="paymentElementOptions"
        @ready="onPaymentElementReady"
        @click="onPaymentElementClick"
        @change="onFieldElementChange('stripeElement')"
      />

      <!-- Email Address -->
      <div class="input-field mt-20">
        <label class="custom-field-label" for="input-email">Email address</label>
        <input
          id="input-email"
          type="email"
          v-model="emailAddressModel"
          autocomplete="email"
          :class="getFieldValidityClass('emailAddress')"
          @change="onFieldElementChange('emailAddress')"
        />
      </div>

      <!-- Shipping Address Form -->
      <h4 class="mt-20">Shipping Address</h4>
      <StripeElement
        ref="addressElement"
        type="address"
        :elements="elementsInstance"
        :options="addressElementOptions"
        @ready="onAddressElementReady"
        @change="onFieldElementChange('stripeElement')"
      />

      <!-- Shipping Methods Form -->
      <ShippingMethodsForm
        v-if="isShowShippingMethodsForm"
        class="mt-20"
        :onShippingMethodSelected="onShippingRateChanged"
      />

      <!-- Validation Advisory Message -->
      <div v-if="!isAllFormValid()" class="colour-danger mt-30" data-test="invalid-form-message">
        Please check all fields are completed.
      </div>

      <!-- Submission Buttons -->
      <div class="mt-20" v-if="isAllElementsAreReady()" data-test="submission-btns-container">
        <button
          v-if="currentFormStep === 'INITIAL'"
          type="button"
          :disabled="isSubmissionInProgress || !isAllFormValid()"
          @click="onSubmitAddressForm"
          data-test="submission-btn-submit-address"
        >
          Continue
        </button>

        <button
          v-if="currentFormStep === 'SHIPPING_METHODS'"
          type="button"
          :disabled="isSubmissionInProgress || !isAllFormValid()"
          @click="onCompletePayment"
          data-test="submission-btn-submit-payment"
        >
          Complete Payment
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, toRef } from 'vue';
import { StripeElement } from 'vue-stripe-js';

import { useAdvertiserStore } from '@/stores/AdvertiserStore';
import { StripePaymentError } from '@/types/errors.types';
import ShippingMethodsForm from '@/components/ShippingMethods/index.vue';
import { isValidEmailAddress } from '@/helpers/validation';

type PaymentComponentType = 'PaymentElement' | 'AddressElement';
type FormStep = 'INITIAL' | 'SHIPPING_METHODS';

export default defineComponent({
  name: 'StripeFieldsCardPayment',
  components: {
    StripeElement,
    ShippingMethodsForm,
  },
  props: {
    stripeInstance: null,
    elementsInstance: null,
    onReady: { type: Function, required: true },
    onShippingAddressChanged: { type: Function, required: true },
    onShippingRateChanged: { type: Function, required: true },
    createConfirmationToken: { type: Function, required: true },
    onPaymentElementConfirmed: { type: Function, required: true },
    allowedCountryCodes: Array,
    totalPaymentPrice: Number,
    currencyCode: String,
  },
  setup(props) {
    const advertiserStore = useAdvertiserStore();

    const paymentElement = ref();
    const addressElement = ref();
    const isSubmissionInProgress = ref(false);
    const isShowShippingMethodsForm = ref(false); // TODO hide when address form is dirty?

    const elementsInstance = toRef(props, 'elementsInstance');
    const totalPaymentPrice = toRef(props, 'totalPaymentPrice');

    const componentReadiness = ref<Record<PaymentComponentType, boolean>>({
      PaymentElement: false,
      AddressElement: false,
    });

    // Keep track of validation state for our own custom purposes
    const customValidationState = ref<Record<'stripeElement' | 'emailAddress', 'INVALID' | null>>({
      stripeElement: null,
      emailAddress: null,
    });

    const paymentElementOptions = ref({
      // https://stripe.com/docs/stripe.js#element-options
      wallets: {
        applePay: 'never',
        googlePay: 'never',
      },
    });

    const addressElementOptions = ref({
      // https://stripe.com/docs/stripe.js#element-options
      mode: 'shipping',
      display: {
        name: 'split',
      },
      allowedCountries: props.allowedCountryCodes,
    });

    const currentFormStep = ref<FormStep>('INITIAL');

    const emailAddressModel = ref<string>();

    const isAllElementsAreReady = () => Object.values(componentReadiness.value).every((val) => val);

    const doWhenAllElementsAreReady = () => {
      if (isAllElementsAreReady()) {
        props.onReady();
      }
    };

    const onPaymentElementReady = () => {
      componentReadiness.value = {
        ...componentReadiness.value,
        PaymentElement: true,
      };

      doWhenAllElementsAreReady();
    };

    const onAddressElementReady = () => {
      componentReadiness.value = {
        ...componentReadiness.value,
        AddressElement: true,
      };

      doWhenAllElementsAreReady();
    };

    const onPaymentElementClick = (e) => {
      console.log('onPaymentElementClick() called');

      const options = {
        shippingAddressRequired: true,
        allowedShippingCountries: ['GB'],
        shippingRates: [
          {
            id: 'unspecified',
            displayName: 'Getting shipping options...',
            amount: 0,
          },
        ],
      };

      e.resolve(options);
    };

    const onSubmitAddressForm = async (event) => {
      console.log('onSubmitAddressForm() called', event);

      event.preventDefault();
      event.stopPropagation();

      isSubmissionInProgress.value = true;

      // Reset custom validation state
      customValidationState.value = {
        ...customValidationState.value,
        ['stripeElement']: null,
      };

      // Validate custom email address field
      onFieldElementChange('emailAddress');

      // Trigger form validation and wallet collection

      const { error: submitError } = await elementsInstance.value.submit();

      isSubmissionInProgress.value = false;

      if (submitError) {
        console.error('A submission error occured', submitError);

        // Assume a validation error here
        customValidationState.value = {
          ...customValidationState.value,
          ['stripeElement']: 'INVALID',
        };

        return;
      }

      const shippingAddressElement = elementsInstance.value.getElement('address');

      const { complete: isShippingAddressValueComplete, value: shippingAddressValue } =
        await shippingAddressElement.getValue();

      if (isShippingAddressValueComplete) {
        isSubmissionInProgress.value = true;

        await props.onShippingAddressChanged({
          resolve: () => {},
          address: {
            streetAddress: [shippingAddressValue.address.line1, shippingAddressValue.address.line2],
            city: shippingAddressValue.address.city,
            region: shippingAddressValue.address.state,
            country: shippingAddressValue.address.country,
            postalCode: shippingAddressValue.address.postal_code,
          },
        });

        isShowShippingMethodsForm.value = true;
        currentFormStep.value = 'SHIPPING_METHODS';
        isSubmissionInProgress.value = false;
      } else {
        // TODO
        isSubmissionInProgress.value = false;
        customValidationState.value = {
          ...customValidationState.value,
          ['stripeElement']: 'INVALID',
        };
      }
    };

    const onCompletePayment = async (event) => {
      console.log('onCompletePayment() called', event);

      event.preventDefault();
      event.stopPropagation();

      isSubmissionInProgress.value = true;

      try {
        const confirmationToken = await props.createConfirmationToken(
          props.stripeInstance,
          props.elementsInstance,
        );

        const { shipping } = confirmationToken;
        const { billing_details: billingDetails } = confirmationToken.payment_method_preview;

        await props.onPaymentElementConfirmed({
          shippingAddress: shipping,
          billingDetails: {
            ...billingDetails,
            email: emailAddressModel.value?.trim(),
          },
          confirmationToken,
          amount: props.totalPaymentPrice! * 100, // In pence
          currencyCode: props.currencyCode,
        });

        isSubmissionInProgress.value = false;
      } catch (err) {
        isSubmissionInProgress.value = false;

        throw new StripePaymentError(
          'An unhandled error occurred when attempting to create the confirmation token!',
          err,
          advertiserStore.paymentProvider!,
        );
      }
    };

    const onFieldElementChange = (fieldName: 'stripeElement' | 'emailAddress') => {
      console.log('onFieldElementChange() called', fieldName);

      let stateFlag: string | null = null;

      // Reset validation state
      customValidationState.value = {
        ...customValidationState.value,
        [fieldName]: null,
      };

      // Validate the field
      switch (fieldName) {
        case 'emailAddress':
          stateFlag = isValidEmailAddress(emailAddressModel.value!) ? null : 'INVALID';

        default:
      }

      customValidationState.value = {
        ...customValidationState.value,
        [fieldName]: stateFlag,
      };
    };

    const getFieldValidityClass = (fieldName: string) => {
      const invalidFieldClass = 'input--invalid';

      return customValidationState.value[fieldName] === 'INVALID' ? invalidFieldClass : '';
    };

    const isAllFormValid = () => {
      return Object.values(customValidationState.value).every((val) => val !== 'INVALID');
    };

    return {
      elementsInstance: elementsInstance,
      isSubmissionInProgress,
      onSubmitAddressForm,
      onCompletePayment,
      paymentElement,
      paymentElementOptions,
      onPaymentElementReady,
      onPaymentElementClick,
      addressElement,
      addressElementOptions,
      onAddressElementReady,
      isShowShippingMethodsForm,
      totalPaymentPrice,
      isAllElementsAreReady,
      currentFormStep,
      emailAddressModel,
      getFieldValidityClass,
      onFieldElementChange,
      isAllFormValid,
      componentReadiness,
      customValidationState,
    };
  },
});
</script>

<style lang="scss" scoped>
@import './StripeFieldsCardPayment.scss';
</style>
