<template>
  <div class="mb-20" ref="paymentContainerRef" />
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, computed } from 'vue';

import { useMainStore } from '@/stores/MainStore';
import { useShippingStore } from '@/stores/ShippingStore';
import { useCartStore } from '@/stores/CartStore';
import { useAdvertiserStore } from '@/stores/AdvertiserStore';
import { usePaymentStore } from '@/stores/PaymentStore';
import { Api as PaymentApi } from '@/api/payment/adyen';
import gtmTracker from '@/helpers/googleTagManager';

import type { PropType } from 'vue';
import type { PaymentButtonTypeAdyen } from '@/stores/MainStore';
import { AdyenPaymentError } from '@/types/errors.types';
import { GooglePaymentError } from '@/types/errors.types.ts'; // TODO use payment type error in places where i've lazily just used AdyenPaymentError

export default defineComponent({
  name: 'AdyenExpressGooglePay',
  components: {},
  props: {
    // TODO use this TS PropType technique in other components!
    onReady: { type: Function as PropType<(p: PaymentButtonTypeAdyen) => void>, required: true },
    adyenSdk: null,
    paymentsClient: null,
    currency: { type: String, required: true },
    countryCode: { type: String, required: true },
  },
  setup(props) {
    const mainStore = useMainStore();
    const shippingStore = useShippingStore();
    const cartStore = useCartStore();
    const advertiserStore = useAdvertiserStore();
    const paymentStore = usePaymentStore();

    const paymentContainerRef = ref();

    const totalPaymentPrice = computed(() => cartStore.getTotalPaymentPrice);

    const onPaymentElementReady = () => {
      props.onReady('googlepay');
    };

    const onPaymentError = (error) => {
      const errorFn = () => {
        mainStore.goToErrorPage({
          error,
        });
      };

      errorFn();

      /* TODO
      if (this.googlePaymentInstance?.teardown) {
        return this.googlePaymentInstance.teardown(errorFn);
      } else {
        errorFn();
      }
        */
    }; // TODO consider applying this to other components

    const initPaymentElement = async () => {
      // TODO remove async in time
      const googlePayElement = new props.adyenSdk!.GooglePay(props.paymentsClient, {
        isExpress: true,
        callbackIntents: ['PAYMENT_AUTHORIZATION', 'SHIPPING_ADDRESS', 'SHIPPING_OPTION'],
        emailRequired: true,
        shippingAddressRequired: true,
        shippingAddressParameters: {
          allowedCountryCodes: ['GB'], // TODO
          phoneNumberRequired: true,
        },
        shippingOptionRequired: true,
        billingAddressRequired: true,
        billingAddressParameters: {
          format: 'FULL',
          phoneNumberRequired: true,
        },
        // shippingOptions: [],
        transactionInfo: {
          displayItems: [
            {
              label: 'Subtotal',
              type: 'SUBTOTAL',
              price: (totalPaymentPrice.value || 0).toString(),
            },
          ],
          countryCode: props.countryCode,
          currencyCode: props.currency,
          totalPriceStatus: 'ESTIMATED',
          totalPrice: (totalPaymentPrice.value || 0).toString(),
          totalPriceLabel: 'Total',
        },
        paymentDataCallbacks: {
          onPaymentDataChanged: async (intermediatePaymentData: ToDo) => {
            try {
              const result = await onPaymentDataChanged(intermediatePaymentData);
              return result;
            } catch (err) {
              console.error('Error in onPaymentDataChanged()');
              return onPaymentError(err);
            }
          },
        },
        onSubmit: async (state: ToDo, component: ToDo, actions: ToDo) => {
          console.log('onSubmit() called', state, component, actions);

          try {
            const submissionResponse = await onSubmit(state, component, actions);
            return actions.resolve(submissionResponse);
          } catch (err) {
            console.error('Error in onSubmit()');
            actions.reject();
            return onPaymentError(err);
          }
        },
        onAuthorized: async (data: ToDo, actions: ToDo) => {
          try {
            await onAuthorized(data, actions);
            return actions.resolve();
          } catch (err) {
            console.error('Error in onAuthorized()');
            actions.reject();
            return onPaymentError(err);
          }
        },
      });

      googlePayElement.mount(paymentContainerRef.value);

      onPaymentElementReady();
    };

    const onPaymentDataChanged = (intermediatePaymentData: ToDo) => {
      console.log('onPaymentDataChanged() called:', intermediatePaymentData);

      return new Promise(async (resolve) => {
        const { callbackTrigger, shippingAddress, shippingOptionData } = intermediatePaymentData;
        const paymentDataRequestUpdate = {};

        // Validate the country/region and address selection.
        if (shippingAddress.countryCode !== 'UK') {
          // TODO allowed shipping countryCodes
          paymentDataRequestUpdate.error = {
            reason: 'SHIPPING_ADDRESS_UNSERVICEABLE',
            message: 'Cannot ship to the selected address',
            intent: 'SHIPPING_ADDRESS',
          };
        }

        try {
          let paymentDataRequestUpdate = {};
          let newShippingOptions = [];
          let shippingOptionData = intermediatePaymentData.shippingOptionData;

          let address = {
            streetAddress: [''],
            city: intermediatePaymentData.shippingAddress.locality,
            region: intermediatePaymentData.shippingAddress.administrativeArea,
            postalCode: intermediatePaymentData.shippingAddress.postalCode,
            country: intermediatePaymentData.shippingAddress.countryCode,
          };

          shippingStore.setAddress('billing', address);
          shippingStore.setAddress('shipping', address);

          await shippingStore.fetchShippingMethods();

          (shippingStore.shippingMethods || []).forEach((method) => {
            newShippingOptions.push({
              id: method.code,
              label: `${method.label} (${method.amount} ${props.currency})`,
              description: '',
            });
          });

          const currentShipping =
            shippingOptionData.id === 'shipping_option_unselected'
              ? newShippingOptions[0].id
              : shippingOptionData.id;

          // Set shipping methods in google modal
          paymentDataRequestUpdate.newShippingOptionParameters = {
            defaultSelectedOptionId: currentShipping,
            shippingOptions: newShippingOptions,
          };

          // Update Shipping code to selected code
          await cartStore.setShippingMethod(currentShipping);

          paymentDataRequestUpdate.newTransactionInfo = {
            displayItems: [
              {
                label: 'Shipping',
                type: 'LINE_ITEM',
                price: (cartStore.cart?.pricing?.shippingPrice || 0).toString(),
                status: 'FINAL',
              },
            ],
            countryCode: props.countryCode,
            currencyCode: props.currency,
            totalPriceStatus: 'FINAL',
            totalPrice: totalPaymentPrice.value.toString(),
            totalPriceLabel: 'Total',
          };

          return resolve(paymentDataRequestUpdate);
        } catch (err) {
          throw new GooglePaymentError(
            'Error in onPaymentDataChanged()!',
            err,
            advertiserStore.paymentProvider!,
          );
        }
      });
    };

    const onAuthorized = async (data: ToDo, actions: ToDo) => {
      console.log('aa onPaymentAuthorized() called', { data, actions });

      const {
        authorizedEvent,
        billingAddress: billingAddressData,
        deliveryAddress: shippingAddressData,
      } = data;

      const shippingAddress = {
        streetAddress: [shippingAddressData.houseNumberOrName, shippingAddressData.street],
        city: shippingAddressData.city,
        postalCode: shippingAddressData.postalCode,
        country: shippingAddressData.country,
        region: authorizedEvent.shippingAddress.administrativeArea,
      };

      const billingAddress = {
        streetAddress: [billingAddressData.houseNumberOrName, billingAddressData.street],
        city: billingAddressData.city,
        postalCode: billingAddressData.postalCode,
        country: billingAddressData.country,
        region: authorizedEvent.paymentMethodData.info.billingAddress.administrativeArea,
      };

      shippingStore.setAddress('shipping', shippingAddress);
      shippingStore.setAddress('billing', billingAddress);

      shippingStore.setCustomer('shipping', {
        // Not known
        firstName: undefined,
        lastName: undefined,
      });

      shippingStore.setCustomer('billing', {
        firstName: authorizedEvent.paymentMethodData.info.billingAddress.name,
        lastName: authorizedEvent.paymentMethodData.info.billingAddress.name,
        phone: authorizedEvent.paymentMethodData.info.billingAddress.phoneNumber,
        email: authorizedEvent.email,
      });
    };

    const onSubmit = async (state: ToDo, component: ToDo, actions: ToDo) => {
      const { paymentMethod } = state.data;
      const amount = {
        value: totalPaymentPrice.value * 100,
        currency: props.currency,
      };

      const resultData = await PaymentApi.submitPayment({
        amount,
        paymentMethod,
      });

      paymentStore.setAdyenPaymentData({
        amount,
        paymentMethod,
        shopthruPspReference: resultData.shopthruPspReference,
      });

      await paymentStore.placeAdyenOrder();

      gtmTracker.doPlaceOrderGtmEvent({
        paymentType: 'GooglePay',
      });

      return resultData;
    };

    onMounted(() => {
      initPaymentElement();
    });

    return {
      paymentContainerRef,
      totalPaymentPrice,
    };
  },
});

/* TODO
googlepay
   .isAvailable()
   .then(() => {
       googlePayComponent.mount("#googlepay-container");
   })
   .catch(e => {
       // Google Pay isn't available.
   });
*/

// TODO payments client isReadyToPay
</script>
